<template>
  <div>
    <title-nav :title="'메일인증 실패 로그'" :nav="'SYSTEM 관리 / 메일인증 실패 로그'" />
    <section class="content">
      <b-row class="mb-2">
        <b-col class="col-10 mb-2">
          <b-input-group size="sm">
            <datepicker
              id="from-datepicker-util"
              v-model="start_date"
              v-on:selected="changeStartDate"
              name="start_date"
              :value="start_date.date"
              :language="languages[language]"
              :format="start_format"
              placeholder="시작일"
            ></datepicker>
            ~
            <datepicker
              id="to-datepicker-util"
              v-model="end_date"
              v-on:selected="changeEndDate"
              name="end_date"
              :value="end_date.date"
              :language="languages[language]"
              :format="end_format"
              placeholder="종료일"
            ></datepicker>
          </b-input-group>
        </b-col>
        <b-col class="d-flex flex-row-reverse">
          <b-button-group class="btn-group-sm">
            <b-button :disabled="page === 1" @click="setPage(-1)">Prev</b-button>
            <b-button :disabled="isMore === false" @click="setPage(1)">Next</b-button>
          </b-button-group>
        </b-col>
      </b-row>
      <b-table
        striped
        hover
        bordered
        class="table-sm text-sm-center"
        :borderless="false"
        :dark="false"
        :fixed="false"
        :no-border-collapse="false"
        :items="items"
        :fields="fields"
        :head-variant="headVariant"
        :busy="isBusy"
        show-empty
      >
        <thead>
          <tr class="td-title"></tr>
        </thead>
      </b-table>
    </section>
  </div>
</template>

<script>
import { sns_list } from '@/api/sns'
import Datepicker from 'vuejs-datepicker/dist/vuejs-datepicker.esm.js'
import * as lang from 'vuejs-datepicker/src/locale'

var now = new Date()
var monthFirstDate = new Date(now.getUTCFullYear(), now.getMonth(), 1)
var monthLastDate = new Date(now.getUTCFullYear(), now.getMonth() + 1, 0)

export default {
  name: `SNS Log List`,
  components: { Datepicker },
  data() {
    return {
      page: 1,
      isMore: true,
      isBusy: false,
      list: [],
      start_date: this.$moment(monthFirstDate).format('YYYY-MM-DD'),
      end_date: this.$moment(monthLastDate).format('YYYY-MM-DD'),
      fields: [
        {
          label: 'No.',
          colType: 'span'
        },
        {
          label: 'email',
          colType: 'span'
        },
        {
          label: 'Diagnostic Message',
          colType: 'span'
        },
        {
          label: 'Bounce Time',
          colType: 'span'
        },
        {
          label: 'Bounce Type',
          colType: 'span'
        },
        {
          label: 'Status',
          colType: 'span'
        },
        {
          label: 'Notification Type',
          colType: 'span'
        },
        {
          label: 'Action',
          colType: 'span'
        },
        {
          label: 'reported Datetime',
          colType: 'span'
        }
      ],
      items: [],
      striped: true,
      bordered: true,
      borderless: false,
      small: true,
      hover: true,
      dark: false,
      fixed: false,
      tableVariant: '',
      noCollapse: false,
      headVariant: 'dark',

      filter: '',
      days: [6, 0], // Highlight Saturday's and Sunday's,
      start_format: 'yyyy-MM-dd',
      end_format: 'yyyy-MM-dd',
      eventMsg: null,
      language: 'ko',
      languages: lang
    }
  },
  methods: {
    async getList() {
      this.isBusy = true
      this.items = await sns_list(
        this.$moment(this.start_date).format('YYYY-MM-DD'),
        this.$moment(this.end_date).format('YYYY-MM-DD'),
        this.page
      )
      if (this.items.length === 0) {
        this.isMore = false
      }
      this.isBusy = false
    },
    setPage(page) {
      if (page === -1 && this.isMore === false) {
        this.isMore = true
      }
      this.page = this.page + page
      this.getList()
    },
    changeStartDate(val) {
      this.start_date = this.$moment(val).format('YYYY-MM-DD')
      this.end_date = this.$moment(this.end_date).format('YYYY-MM-DD')
      this.setCondition()
    },
    changeEndDate(val) {
      this.start_date = this.$moment(this.start_date).format('YYYY-MM-DD')
      this.end_date = this.$moment(val).format('YYYY-MM-DD')
      this.setCondition()
    },
    setCondition() {
      if (this.page === 1 && this.isMore === false) {
        this.isMore = true
      }
      this.getList()
    }
  },
  mounted() {
    this.getList()
  }
}
</script>

<style></style>
