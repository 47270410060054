import { axiosGet } from './config'

/**
 * 1:1문의 리스트를 페이지 단위로 가져오기
 **/
export const sns_list = (start_date, end_date, page_no = 1, page_size = 30) => {
  return axiosGet('sns_select_page_by_admin', {
    start_date,
    end_date,
    page_no,
    page_size
  })
}
